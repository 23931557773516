import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { BasicLayout } from '../../layouts/basic'

const WebsiteTermsAndConditionsPage = () => (
  <BasicLayout>
    <Helmet title="Website terms and conditions — Qualdesk" />
    <div className="mx-auto p-6 max-w-6xl text-text-primary-light text-sm leading-relaxed sm:text-base">
      <h1 className="mb-6 text-2xl font-extrabold tracking-tight leading-tight sm:text-3xl">
        Website terms and conditions
      </h1>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">1. </span>
        <span>Introduction</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.1 </span>
        <span>
          These terms and conditions shall govern your use of our website.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.2 </span>
        <span>
          By using our website, you accept these terms and conditions in full;
          accordingly, if you disagree with these terms and conditions or any
          part of these terms and conditions, you must not use our website.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.3 </span>
        <span>
          If you use any of our website services, we will ask you to expressly
          agree to these terms and conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.4 </span>
        <span>
          You must be at least 18 years of age to use our website; by using our
          website or agreeing to these terms and conditions, you warrant and
          represent to us that you are at least 18 years of age.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">1.5 </span>
        <span>
          Our website uses cookies; by using our website or agreeing to these
          terms and conditions, you consent to our use of cookies in accordance
          with the terms of our{' '}
          <Link to="/legal/privacy-and-cookies-policy">
            privacy and cookies policy
          </Link>
          .
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">2. </span>
        <span>Copyright notice</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.1 </span>
        <span>Copyright ©{new Date().getFullYear()} Qualdesk Ltd.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">2.2 </span>
        <span>
          Subject to the express provisions of these terms and conditions:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a) </span>
        <span>
          we, together with our licensors, own and control all the copyright and
          other intellectual property rights in our website and the material on
          our website; and
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b) </span>
        <span>
          all the copyright and other intellectual property rights in our
          website and the material on our website are reserved.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">3. </span>
        <span>Licence to use website</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.1 </span>
        <span>You may:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a) </span>
        <span>view pages from our website in a web browser;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b) </span>
        <span>
          download pages from our website for caching in a web browser;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c) </span>
        <span>print pages from our website;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12"> </span>
        <span>
          subject to the other provisions of these terms and conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.2 </span>
        <span>
          Except as expressly permitted by Section 3.1 or the other provisions
          of these terms and conditions, you must not download any material from
          our website or save any such material to your computer.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.3 </span>
        <span>
          You may only use our website for your own personal and business
          purposes, and you must not use our website for any other purposes.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.4 </span>
        <span>
          Except as expressly permitted by these terms and conditions, you must
          not edit or otherwise modify any material on our website.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.5 </span>
        <span>
          Unless you own or control the relevant rights in the material, you
          must not:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a) </span>
        <span>
          republish material from our website (including republication on
          another website);
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b) </span>
        <span>sell, rent or sub-license material from our website;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c) </span>
        <span>show any material from our website in public;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d) </span>
        <span>
          exploit material from our website for a commercial purpose; or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(e) </span>
        <span>redistribute material from our website.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.6 </span>
        <span>
          Notwithstanding Section 3.5, you may redistribute our blog posts and
          email newsletter in print and electronic form to any person.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">3.7 </span>
        <span>
          We reserve the right to restrict access to areas of our website, or
          indeed our whole website, at our discretion; you must not circumvent
          or bypass, or attempt to circumvent or bypass, any access restriction
          measures on our website.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">4. </span>
        <span>Acceptable use</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.1 </span>
        <span>You must not:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a) </span>
        <span>
          use our website in any way or take any action that causes, or may
          cause, damage to the website or impairment of the performance,
          availability or accessibility of the website;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b) </span>
        <span>
          use our website in any way that is unlawful, illegal, fraudulent or
          harmful, or in connection with any unlawful, illegal, fraudulent or
          harmful purpose or activity;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c) </span>
        <span>
          use our website to copy, store, host, transmit, send, use, publish or
          distribute any material which consists of (or is linked to) any
          spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit
          or other malicious computer software;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d) </span>
        <span>
          conduct any systematic or automated data collection activities
          (including without limitation scraping, data mining, data extraction
          and data harvesting) on or in relation to our website without our
          express written consent;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(e) </span>
        <span>
          access or otherwise interact with our website using any robot, spider
          or other automated means, except for the purpose of search engine
          indexing;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(f) </span>
        <span>
          violate the directives set out in the robots.txt file for our website;
          or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(g) </span>
        <span>
          use data collected from our website for any direct marketing activity
          (including without limitation email marketing, SMS marketing,
          telemarketing and direct mailing).
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.2 </span>
        <span>
          You must not use data collected from our website to contact
          individuals, companies or other persons or entities.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">4.3 </span>
        <span>
          You must ensure that all the information you supply to us through our
          website, or in relation to our website, is true, accurate, current,
          complete and non-misleading.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">5. </span>
        <span>Limited warranties</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.1 </span>
        <span>We do not warrant or represent:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a) </span>
        <span>
          the completeness or accuracy of the information published on our
          website;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b) </span>
        <span>that the material on the website is up to date; or</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c) </span>
        <span>
          that the website or any service on the website will remain available.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.2 </span>
        <span>
          We reserve the right to discontinue or alter any or all of our website
          services, and to stop publishing our website, at any time in our sole
          discretion without notice or explanation; and save to the extent
          expressly provided otherwise in these terms and conditions, you will
          not be entitled to any compensation or other payment upon the
          discontinuance or alteration of any website services, or if we stop
          publishing the website.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">5.3 </span>
        <span>
          To the maximum extent permitted by applicable law and subject to
          Section 6.1, we exclude all representations and warranties relating to
          the subject matter of these terms and conditions, our website and the
          use of our website.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">6. </span>
        <span>Limitations and exclusions of liability</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.1 </span>
        <span>Nothing in these terms and conditions will:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a) </span>
        <span>
          limit or exclude any liability for death or personal injury resulting
          from negligence;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b) </span>
        <span>
          limit or exclude any liability for fraud or fraudulent
          misrepresentation;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c) </span>
        <span>
          limit any liabilities in any way that is not permitted under
          applicable law; or
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d) </span>
        <span>
          exclude any liabilities that may not be excluded under applicable law.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.2 </span>
        <span>
          The limitations and exclusions of liability set out in this Section 6
          and elsewhere in these terms and conditions:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a) </span>
        <span>are subject to Section 6.1; and</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b) </span>
        <span>
          govern all liabilities arising under these terms and conditions or
          relating to the subject matter of these terms and conditions,
          including liabilities arising in contract, in tort (including
          negligence) and for breach of statutory duty, except to the extent
          expressly provided otherwise in these terms and conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.3 </span>
        <span>
          To the extent that our website and the information and services on our
          website are provided free of charge, we will not be liable for any
          loss or damage of any nature.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.4 </span>
        <span>
          We will not be liable to you in respect of any losses arising out of
          any event or events beyond our reasonable control.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.5 </span>
        <span>
          We will not be liable to you in respect of any business losses,
          including (without limitation) loss of or damage to profits, income,
          revenue, use, production, anticipated savings, business, contracts,
          commercial opportunities or goodwill.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.6 </span>
        <span>
          We will not be liable to you in respect of any loss or corruption of
          any data, database or software.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.7 </span>
        <span>
          We will not be liable to you in respect of any special, indirect or
          consequential loss or damage.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">6.8 </span>
        <span>
          You accept that we have an interest in limiting the personal liability
          of our officers and employees and, having regard to that interest, you
          acknowledge that we are a limited liability entity; you agree that you
          will not bring any claim personally against our officers or employees
          in respect of any losses you suffer in connection with the website or
          these terms and conditions (this will not, of course, limit or exclude
          the liability of the limited liability entity itself for the acts and
          omissions of our officers and employees).
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">7. </span>
        <span>Breaches of these terms and conditions</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.1 </span>
        <span>
          Without prejudice to our other rights under these terms and
          conditions, if you breach these terms and conditions in any way, or if
          we reasonably suspect that you have breached these terms and
          conditions in any way, we may:
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a) </span>
        <span>send you one or more formal warnings;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b) </span>
        <span>temporarily suspend your access to our website;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(c) </span>
        <span>permanently prohibit you from accessing our website;</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(d) </span>
        <span>
          block computers using your IP address from accessing our website;
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(e) </span>
        <span>
          commence legal action against you, whether for breach of contract or
          otherwise.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">7.2 </span>
        <span>
          Where we suspend or prohibit or block your access to our website or a
          part of our website, you must not take any action to circumvent such
          suspension or prohibition or blocking.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">8. </span>
        <span>Variation</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.1 </span>
        <span>We may revise these terms and conditions from time to time.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.2 </span>
        <span>
          The revised terms and conditions shall apply to the use of our website
          from the date of publication of the revised terms and conditions on
          the website, and you hereby waive any right you may otherwise have to
          be notified of, or to consent to, revisions of these terms and
          conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">8.3 </span>
        <span>
          If you have given your express agreement to these terms and
          conditions, we will ask for your express agreement to any revision of
          these terms and conditions; and if you do not give your express
          agreement to the revised terms and conditions within such period as we
          may specify, we will disable or delete your account on the website,
          and you must stop using the website.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">9. </span>
        <span>Assignment</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">9.1 </span>
        <span>
          You hereby agree that we may assign, transfer, sub-contract or
          otherwise deal with our rights and/or obligations under these terms
          and conditions.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">9.2 </span>
        <span>
          You may not without our prior written consent assign, transfer,
          sub-contract or otherwise deal with any of your rights and/or
          obligations under these terms and conditions.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">10. </span>
        <span>Severability</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">10.1 </span>
        <span>
          If a provision of these terms and conditions is determined by any
          court or other competent authority to be unlawful and/or
          unenforceable, the other provisions will continue in effect.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">10.2 </span>
        <span>
          If any unlawful and/or unenforceable provision of these terms and
          conditions would be lawful or enforceable if part of it were deleted,
          that part will be deemed to be deleted, and the rest of the provision
          will continue in effect.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">11. </span>
        <span>Third party rights</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">11.1 </span>
        <span>
          A contract under these terms and conditions is for our benefit and
          your benefit, and is not intended to benefit or be enforceable by any
          third party.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">11.2 </span>
        <span>
          The exercise of the parties' rights under a contract under these terms
          and conditions is not subject to the consent of any third party.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">12. </span>
        <span>Entire agreement</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">12.1 </span>
        <span>
          Subject to Section 6.1, these terms and conditions, together with our{' '}
          <Link to="/legal/privacy-and-cookies-policy">
            privacy and cookies policy
          </Link>
          , shall constitute the entire agreement between you and us in relation
          to your use of our website and shall supersede all previous agreements
          between you and us in relation to your use of our website.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">13. </span>
        <span>Law and jurisdiction</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.1 </span>
        <span>
          These terms and conditions shall be governed by and construed in
          accordance with English law.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">13.2 </span>
        <span>
          Any disputes relating to these terms and conditions shall be subject
          to the exclusive jurisdiction of the courts of England.
        </span>
      </p>
      <h4 className="flex mb-4 mt-8 text-xl font-medium tracking-tight leading-tight">
        <span className="flex-shrink-0 w-12">14. </span>
        <span>Our details</span>
      </h4>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.1 </span>
        <span>This website is owned and operated by Qualdesk Ltd.</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.2 </span>
        <span>
          We are registered in England and Wales under registration number
          11334688 and our registered office is at 7 Henrietta Street, London,
          WC2E 8PS, United Kingdom.
        </span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">14.3 </span>
        <span>You can contact us:</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(a) </span>
        <span>by post, using the postal address given above; or</span>
      </p>
      <p className="flex max-w-prose">
        <span className="flex-shrink-0 w-12">(b) </span>
        <span>
          by email, at <a href="mailto:legal@qualdesk.io">legal@qualdesk.io</a>.
        </span>
      </p>
    </div>
  </BasicLayout>
)

export default WebsiteTermsAndConditionsPage
